
.divStart {
    margin: auto;
    padding-top: 40px;
    width: 300px;
    text-align: center;
    color: black;
    font-weight: bold;
}

.divStart a {
    color: #2D3D53;
}

.header {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
}

.header img {
    width: 100%;
}

.loginButton {
    width: 260px;
    color: white;
    font-size: 20px;
    border: none;
    cursor: pointer;
    font-weight: bold;
    background: #292929;
    padding: 10px;
    border-radius: 5px;
}

.codeInput {
    width: 250px;
    border: none;
    padding: 10px 0px 10px 10px;
    font: 22px/26px "Lato", Arial, sans-serif;
    margin-bottom: 10px;
    border-radius: 5px;
    resize: none;
    text-align: center !important;
}